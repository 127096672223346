import React from "react"

const SidebarEmails = () => {
    return (
      <>
        <h2 className="sidebar-header">Mailing List</h2>
            <div className="sidebar-emails">
              <h2>Mailing list here</h2>
              <p>Subscribe to my list for lots of great reasons</p>
              <form action="https://novmob.us7.list-manage.com/subscribe/post" method="POST">
                <input type="hidden" aria-label=" " name="u" value="783a0616e22588f87f577f37e"/>
                <input type="hidden" aria-label=" " name="id" value="85458c2132"/>
                <input type="email" aria-label="email" autocapitalize="off" autocorrect="off" name="MERGE0" id="MERGE0" size="25"/>
                <input type="submit" value="Subscribe" aria-label="subscribe"/>
              </form>
              <span>Weekly updates, unsubscribe at any time</span>
            </div>
      </>
    );
}
export default SidebarEmails